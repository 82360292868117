<template>
    <div class="GSC_home" id="home" :style="mainColor">
        <div id="EE_hero" class="home-landing py-5" :style="heroStyle">
            <div class="container py-5 z-index-2">
                <div class="row align-items-center">
                    <div class="col-12">
                        <h1 class="CE_hero_h1 mb-4">THE FUNDS<br>YOU WANT FAST.</h1>
                        <h2 class="CE_hero_h2 mb-5">REQUEST LOANS FROM
                            <span class="CE_hero_span text-main-color">$100 - $5,000.</span>
                            ONLY MOMENTS AWAY
                        </h2>
                        <div class="d-flex d-sm-block justify-content-center">
                            <a href="/form" @click="removeRefillData" class="btn btn-orange btn-big btn-banner">
                                Get Started
                                <svg id="btn-banner" xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
                                    <path d="M2 2L8.76327 6.22705C9.35893 6.59933 9.39522 7.45359 8.83327 7.87504L2 13" stroke="white" stroke-width="3" stroke-linecap="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="EE_hiw" style="padding-bottom: 115px">
            <div id="how-it-works" class="pt-5 py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <h2 class="CE_h2 mb-0" data-color="#ff0000">How it Works? <span class="fw-normal CE_h2_text_hiw">It&nbsp;Works&nbsp;Perfectly!</span></h2>
                            <div class="CE_decor decor-bottom" >&nbsp;</div>
                        </div>
                        <div class="col-md-6 d-flex mb-4">
                            <div class="CE_card_bg box">
                                <div class="num CE_card_num"><span>1.</span></div>
                                <div class="info-wrapper">
                                    <h3 class="CE_h3 mb-4">Submit An Online Request.</h3>
                                    <p class="CE_p">Simply complete a safe and secure online loan request form that usually takes less than
                                        five
                                        minutes to submit with your information.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex mb-4">
                            <div class="CE_card_bg box">
                                <div class="num CE_card_num"><span>2.</span></div>
                                <div class="info-wrapper">
                                    <h3 class="CE_h3 mb-4">Agents Online to Help You Connect.</h3>
                                    <p class="CE_p">Once you submit your online request, Agents will be online and may
                                        reach out to you to confirm your submission, answer questions, and finalize
                                        connecting you to a Lender for their services.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex mb-4">
                            <div class="CE_card_bg box">
                                <div class="num CE_card_num" ><span>3.</span></div>
                                <div class="info-wrapper">
                                    <h3 class="CE_h3 mb-4">Get Connected with a Lender and Review Offer.</h3>
                                    <p class="CE_p">In minutes, you may be connected to a lender in our network for loan
                                        offers up to $5,000. Your lender will contact you immediately to present any
                                        loan offer, terms and conditions, and material terms. You are encouraged to
                                        carefully review this information and are free to accept at your discretion.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex mb-4">
                            <div class="CE_card_bg box">
                                <div class="num CE_card_num" ><span>4.</span></div>
                                <div class="info-wrapper">
                                    <h3 class="CE_h3 mb-4">Get Funded!</h3>
                                    <p class="CE_p">Upon agreeing to loan terms with your lender, funds may be transferred to your bank
                                        account within the next business day upon lender approval. Time periods may vary between
                                        lenders for issuing loans and you are encouraged to reach out to them directly!</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5">
                            <div class="text-center">
                                <a href="/form" @click="removeRefillData" class="btn btn-orange btn-big btn-hiw">
                                    Get Started
                                    <svg id="btn-hiw" xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
                                        <path d="M2 2L8.76327 6.22705C9.35893 6.59933 9.39522 7.45359 8.83327 7.87504L2 13" stroke="white" stroke-width="3" stroke-linecap="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="CE_img how-it-works-bg" :style="howItWorksBg">
            </div>
        </div>
        <div id="EE_wu" class="gray-section pt-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-12 pb-5">
                        <h2 class="CE_h2 mb-0">Why us? <span class="fw-normal CE_h2_text_wu">Fast.&nbsp;Easy.&nbsp;Secure.</span> </h2>
                        <div class="CE_decor decor-bottom" >&nbsp;</div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="CE_card box-white">
                            <div class="svg d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="84" height="64.888" style="height: 50px; width: 50px;"
                                     viewBox="0 0 84 64.888" >
                                    <g transform="translate(4.543 20.139)">
                                        <rect class="CE_svg a" width="9.647" height="4.922"/>
                                    </g>
                                    <g transform="translate(4.543 39.827)">
                                        <rect class="CE_svg a" width="9.647" height="4.922"/>
                                    </g>
                                    <g transform="translate(0 29.983)">
                                        <rect class="CE_svg a" width="14.19" height="4.922"/>
                                    </g>
                                    <g transform="translate(19.111)">
                                        <path class="CE_svg a"
                                              d="M148.933,58.245a32.444,32.444,0,1,0,32.444,32.444A32.481,32.481,0,0,0,148.933,58.245Zm0,59.966a27.522,27.522,0,1,1,27.522-27.522A27.554,27.554,0,0,1,148.933,118.211Z"
                                              transform="translate(-116.489 -58.245)"/>
                                    </g>
                                    <g transform="translate(48.223 17.77)">
                                        <path class="CE_svg a" d="M298.852,182.454v-15.9H293.93v18.5l12.683,8.643,2.772-4.067Z"
                                              transform="translate(-293.93 -166.557)"/>
                                    </g>
                                </svg>
                                <h3 class="ms-2 card-title">Fast</h3>
                            </div>
                            <div class="info-wrapper">
                                <p>Tired of waiting for days or even weeks for your money? Our online request process is
                                    quick and easy, which means you can connect with lenders and get funded in no
                                    time.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="CE_card box-white">
                            <div class="svg d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="61.15" height="61.027" style="height: 50px; width: 50px;"
                                     viewBox="0 0 60 60" >
                                    <path class="CE_svg a"
                                          d="M58.265 28.251A2.262 2.262 0 0056 30.514 25.489 25.489 0 1130.514 5.025a2.263 2.263 0 100-4.525 30.014 30.014 0 1030.013 30.014 2.262 2.262 0 00-2.262-2.263z"/>
                                    <path class="CE_svg a"
                                          d="M59.99 8.706a2.262 2.262 0 00-3.2 0L29.65 35.775 19.858 25.14a2.263 2.263 0 10-3.329 3.065l11.387 12.367a2.26 2.26 0 001.616.729h.048a2.265 2.265 0 001.6-.66l28.807-28.732a2.262 2.262 0 00.003-3.203z"/>
                                </svg>
                                <h3 class="ms-2 card-title">Easy</h3>
                            </div>
                            <div class="info-wrapper">
                                <p>Our online process is simple can be completed usually within five minutes, which
                                    means you may connect with our network of lenders easily online. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="CE_svg CE_card box-white">
                            <div class="svg d-flex align-items-center">
                                <svg  xmlns="http://www.w3.org/2000/svg" width="57.369" height="67.993" style="height: 50px; width: 50px;"
                                     viewBox="0 0 57.369 67.993" >
                                    <path class="CE_svg a"
                                          d="M95.731,11.01,69.7.2a2.656,2.656,0,0,0-2.037,0L41.638,11.01A2.656,2.656,0,0,0,40,13.463V26.6A44.478,44.478,0,0,0,67.681,67.8a2.656,2.656,0,0,0,2.007,0A44.479,44.479,0,0,0,97.369,26.6V13.463A2.656,2.656,0,0,0,95.731,11.01ZM92.057,26.6A39.176,39.176,0,0,1,68.684,62.452,39.177,39.177,0,0,1,45.312,26.6V15.236l23.372-9.7,23.372,9.7ZM65.749,35.552,77.165,24.137a2.656,2.656,0,1,1,3.756,3.756L67.627,41.186a2.656,2.656,0,0,1-3.756,0l-7.423-7.423A2.656,2.656,0,1,1,60.2,30.008Z"
                                          transform="translate(-40 0)"/>
                                </svg>
                                <h3 class="ms-2 card-title">Secure</h3>
                            </div>
                            <div class="info-wrapper">
                                <p>Concerned about security? Worry not. With the use of 256bit SSL/TLS technology, your
                                    information will be secure.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-5 pb-5 mt-md-5">
                        <div class="text-center">
                            <a  href="/form" @click="removeRefillData" class="btn btn-wu-cards btn-big">
                                Get Started
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
                                    <path d="M2 2L8.76327 6.22705C9.35893 6.59933 9.39522 7.45359 8.83327 7.87504L2 13" stroke="var(--wu-cards-button-text-color)" stroke-width="3" stroke-linecap="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="EE_wu_picture">
            <div class="row mx-0">
                <div class="col-12 p-0">
                    <div class="CE_green_box green-box">
                        <div class="position-relative z-index-2" >
                            <h2 class="CE_h2_2 mb-5">Solve your financial emergency quickly
                                using our easy to use form</h2>
                        </div>
                        <a  href="/form" @click="removeRefillData" class="CE_btn_color CE_btn_text_color btn mt-5 btn-white btn-big mx-1">
                            Get Started
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
                                <path d="M2 1.5L8.76327 5.72705C9.35893 6.09933 9.39522 6.95359 8.83327 7.37504L2 12.5" stroke="var(--wu-button-text-color)" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="EE_faq"  class="py-md-5">
            <div id="faq" class="pt-5 py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-12 pb-5">
                            <h2 class="CE_h2 mb-0" data-color="#ff0000">F.A.Q.<span class="fw-normal CE_h2_text_faq"> We&nbsp;Have&nbsp;The&nbsp;Answer!</span></h2>
                            <div class="CE_decor decor-bottom" >&nbsp;</div>
                        </div>
                        <div class="col-12">
                            <div class="accordion" id="accordionFAQ">
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingOne">
                                        <button class="CE_h2 accordion-button btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                            <span style="padding-right: 12px">What is a Short-term Loan?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    Short-term loans such as personal loans or cash advances require no guaranty and
                                                    provide you with emergency cash for unexpected circumstances. These types of
                                                    loans are intended to be used only in extraordinary circumstances as repayment
                                                    periods, rates, and fees tend to differ from more traditional financial
                                                    products. Those who wish to accept a short-term loan from a lender they may
                                                    connect with on this website should be responsible in repaying the loan on
                                                    time. Loan amounts will vary but are typically between $500 and $5,000.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingTwo">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span style="padding-right: 12px">Who is Lending Me Money?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    Our website works with an extensive network of state licensed lenders, tribal
                                                    lenders, and reputable financial service providers nationwide who may help you
                                                    secure a loan. Our job is to connect you with these lenders so that you may
                                                    potentially secure funding. Once connected, lenders will typically reach out to
                                                    you within minutes to discuss lending options. It is important to remember that
                                                    this website is not a lender nor do we provide short-term loans but instead pass
                                                    your information to lenders in our network so that you may be connected to loans
                                                    and financial products.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingThree">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span style="padding-right: 12px">How does the Website Work?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    Upon completing our website’s loan request form and hitting submit, we will pass
                                                    your information to our network of lenders in an attempt to connect you. If a
                                                    lender chooses to offer you a loan, they may contact you typically within
                                                    minutes via email, phone, or text, and present you with the next steps to accept
                                                    their offer and complete their loan application. At this time, you are
                                                    encouraged to carefully review all terms of the loan and ask any clarifying
                                                    questions. You are under no obligation during the process to accept any loan
                                                    offered and may choose to end the process at your discretion. In the event you
                                                    are not able to connect with a lender, this website may pass the information you
                                                    submitted to third-parties who may offer you related financial products.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingFour">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <span style="padding-right: 12px">How Much Does It Cost?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    Using our website’s services to connect with lenders is completely free. You may
                                                    use this website as often as needed, free of any charges. However, if you are
                                                    successfully connected with a lender through this site, you may be responsible
                                                    to the lender for the costs associated with the loan you may potentially receive
                                                    and those costs and fees are determined solely by the lender. It is important to
                                                    remember that short-term loans are intended for emergency cash circumstances and
                                                    often more expensive than more traditional financial products.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingFive">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            <span style="padding-right: 12px">What are the Requirements to Submit a Loan Request?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    To submit a request on this website to be connected with lenders for short-term
                                                    loans, you must meet some basic requirements and provide certain information.
                                                    The lenders in our network seek to view this information so that they may
                                                    determine your creditworthiness and whether to offer you a loan. We will only
                                                    pass the sensitive information you provide to us on the loan request form to
                                                    lenders and financial service providers who offer loans. In order to be eligible
                                                    to submit a loan request form, you must ensure you are: (i) 18 years or older;
                                                    (ii) Have a valid social security number; (iii) Be a U.S. citizen or permanent
                                                    resident; (iv) Have a consistent monthly income source; (v) Own a valid bank
                                                    account for deposits and repayment; and (vi) Own a valid telephone number and
                                                    email address. Notwithstanding, each lender in our network may have different
                                                    rules and regulations to decide if a borrower is eligible for a loan and thus,
                                                    any final decision pertaining to lending or loans belongs to the lender alone
                                                    and not us. This website is not a lender and does not make credit decisions or
                                                    review your information for creditworthiness.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingSix">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <span style="padding-right: 12px">Will My Credit Be Pulled and Checked?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    This website does not perform credit checks or pull credit to determine
                                                    creditworthiness. We are not a lender and do not make any financial decisions.
                                                    However, the lenders who receive the information you affirmatively provide to us
                                                    on this website may use your information to verify consumer identity and
                                                    perform credit checks which may impact your credit score. Lenders in our network
                                                    may perform a credit checks or secure your credit score before making their
                                                    decision to offer you a loan by using the three major reporting bureaus like,
                                                    TransUnion, Experian and Equifax, as well as other alternate sources of consumer
                                                    information. By submitting your loan request on this website, you are providing
                                                    express written consent and authorization under the Fair Credit Reporting Act to
                                                    allow such lenders to use your information in this manner (to obtain credit
                                                    reports, credit scores, or other information from any consumer reporting
                                                    agency).
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingSeven">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <span style="padding-right: 12px">What Are the Lenders Potential Rates and Fees?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    It is important to remember that our website is not a lender but instead passes
                                                    consumer’s information from those who submit loan requests on this website to
                                                    our network of lenders who may provide such loans. Therefore, we are unable to
                                                    provide you with exact APR (Annual Percentage Rate) that you will be charged for
                                                    a loan since this is determined solely by the lender you connect with and will
                                                    vary among different lenders. The loan interest rates are determined only and
                                                    solely by your lender, with specific amounts determined based upon the
                                                    information you submitted to the lender. Your lender is required to provide you
                                                    with the APR, loan fees, and other information.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingEight">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <span style="padding-right: 12px">What Are the Loan Repayment Terms?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    This website does not determine loan repayment terms. Loan repayment terms are
                                                    determined solely by the lender you connect with and are usually scheduled to be
                                                    made once or twice a month, depending on the terms and conditions of your loan
                                                    agreement. Understanding the repayment terms of your loan is very important.
                                                    Each offer has different factors affecting the terms, such as your state of
                                                    residence, the length of repayment time, or your total amount of the loan.
                                                    Please reach your lender for further information about your repayment terms and
                                                    review all repayment information prior to accepting a loan offer.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingNine">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                            <span style="padding-right: 12px">What Happens in The Event of A Late Payment or Non Payment?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    As mentioned previously, this website is not a lender and therefore cannot
                                                    provide any specific information regarding late payment or non-payment. In the
                                                    case that you are unable to make a payment on time to your lender, you are
                                                    encouraged to contact your lender immediately. Late payment may result in
                                                    additional fees depending on your lender’s terms and conditions and your state’s
                                                    regulations. Reviewing your agreement with the lender to acknowledge the
                                                    consequences of late payment is highly recommended. Failing to repay your loan
                                                    can lead to several penalties imposed by lenders, including but not limited to,
                                                    charges for late payment, forwarding your account to a collection agency, or
                                                    reporting your lack of payment to a credit bureau which can negatively impact
                                                    your credit score. It is important to remember that non-payment of loans may
                                                    impact your credit score negatively if your credit provider chooses to report
                                                    delinquencies. This website is not a debt collector nor do we engage in any
                                                    collections. You are encouraged to contact your lender directly in the event of
                                                    nonpayment.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="CE_item accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingTen">
                                        <button class="CE_h2 accordion-button collapsed btn btn-link btn-block text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                            <span style="padding-right: 12px">What If I am Not Connected?</span>
                                        </button>
                                    </h2>
                                    <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionFAQ">
                                        <div class="accordion-body">
                                            <div class="card-body">
                                                <div class="CE_text pt-4">
                                                    Lenders in our network will ultimately determine whether you will be offered a
                                                    loan or are eligible for certain loan products. Our website is not a lender and
                                                    does not make financial determinations. If you are unable to connect with a
                                                    lender for a loan on this website, we may offer you the opportunity get
                                                    connected with other lender networks which provide smaller amounts of up to
                                                    $1,500. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {handleGetParams, countVisitor, mountedHomePage} from "../../../common/js/commonGeneral";
import {submit as submitWithPubId} from "../../../common/js/reapplyForms";
export default {
    data() {
        return {
            app_name: document.querySelector('title').text,
            // token: document.querySelector('meta[name="form-token"]').content,
            heroImage: siteSettings.elements.EE_hero['background-image'],
            buttonColor: siteSettings.general.main_button_color.value,
            mainColor: siteSettings.general.main_color.value,
            linkColor: siteSettings.general.link_color.value,
            howItWorksBg: siteSettings.elements.EE_hiw.CE_img['background-image'],
            wuButtonTextColor: siteSettings.elements?.EE_wu_picture?.CE_btn_text_color['color'],
            secondaryTitleColorFAQ: siteSettings?.elements?.EE_faq?.CE_h2_text['color'] || '#707070',
            secondaryTitleColorWU: siteSettings?.elements?.EE_wu?.CE_h2_text['color'] || '#707070',
            secondaryTitleColorHIW: siteSettings?.elements?.EE_hiw?.CE_h2_text['color'] || '#707070',
            cardTitleColorWu: siteSettings?.elements?.EE_wu?.CE_card_headers['color'] || '#707070',
            wuCardsButtonTextColor: siteSettings?.elements?.EE_wu?.CE_wu_button['color'] || '#fff',
            wuCardsButtonBGColor: siteSettings?.elements?.EE_wu?.CE_wu_button['background-color'] || '#707070',
        }
    },
    computed: {
        heroStyle() {
            return 'background-image: "' + this.heroImage + '"';
        },
        howItWorksBg() {
            return 'background-image: ' + this.howItWorksBg + ';background-size: cover;background-position: center center;';
        },
        mainColor() {
            return {
                '--main-color': this.mainColor,
                '--main-button-color': this.buttonColor,
                '--link-color': this.linkColor,
                '--wu-button-text-color' :this.wuButtonTextColor,
                '--second-title-color-hiw': this.secondaryTitleColorHIW,
                '--second-title-color-wu': this.secondaryTitleColorWU,
                '--second-title-color-faq': this.secondaryTitleColorFAQ,
                '--wu-card-title-color': this.cardTitleColorWu,
                '--wu-cards-button-text-color': this.wuCardsButtonTextColor,
                '--wu-cards-button-bg-color': this.wuCardsButtonBGColor,
            };
        },
    },
    async mounted() {
        await handleGetParams(this);
        await countVisitor(this);
        mountedHomePage(this);
    },
    methods:{
        removeRefillData() {
            this.$cookies.remove('customerState');
            this.$cookies.remove('customerDataMain');
        },
        submitWithPubId
    }
};
</script>

<style lang="scss" scoped>
    @import "../../sass/home.scss";
</style>
